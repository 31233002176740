<template>
  <dash-page-new
      :root="$t('Dashboard')"
      :root-location="businessDashLink('')"
      :title="$t('Clients')"
      :subtitle="$t('All')"
      icon="mdi-account-multiple"
      :filters="filterItems"
      @filter="filter = $event"
      filters-input=""
      no-side >

    <template #header_action.desktop>
      <v-btn @click="displayNewClient" dark :color="wsATTENTION" class="noCaps"  >
        <v-icon>mdi-plus</v-icon>
        {{ $t('AddClient') }}
      </v-btn>
    </template>
    <template #header_action.mobile>
      <v-btn @click="displayNewClient" icon dark :color="wsATTENTION" class="noCaps"  >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>

    <template #default>

      <div class="d-flex justify-end align-center ">
        <v-btn  @click="exportData" icon class="noCaps" :color="wsACCENT" outlined>
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </div>

      <!--  Clients-->
      <v-sheet class="wsRoundedHalf py-6">
        <ws-data-table
            :items="itemsFiltered"
            :headers="headers"
            :search="filter.search"
            @current-items="getExportData"
        >
          <template v-slot:item="{item,index}">
            <tr class="pointer" @click="$router.push(businessDashLink('subscribers/' + item.uuid  + '/timeline' ))"  @contextmenu="openMenu($event, item)">

              <td>
                <div class="d-flex align-center text-no-wrap">
                  <v-avatar class="mr-3"  :color="wsACCENT"
                            size="40" key="profile_picture" >
                    <v-img v-if="item.img" :src="item.img" transition="xxx">
                      <template slot="placeholder">
                        <v-img src="@/assets/placeholder/user_blank.jpg" />
                      </template>
                    </v-img>
                    <v-icon  dark v-else>mdi-account-tie</v-icon>
                  </v-avatar>
                  <div>
                    <h5> {{ item.name }}  </h5>
                    <h6 class="font-weight-light"> {{ item.email}}</h6>
                    <v-chip v-for="(tag,j) in item.tags" :key="'tag_' + index + '_' + j" x-small outlined :color="tag.color" class="px-1">
                      <h6>{{ tag.name }}</h6>
                    </v-chip>
                  </div>
                </div>
              </td>
              <td width="10px">
                <ws-chip icon="mdi-phone"  :text="item.phone"  :color="wsACCENT" />
              </td>
              <td  width="10px" class="text-no-wrap">
                <ws-chip icon="mdi-calendar" :color="wsACCENT" :text="PARSE_TIME_STAMP(item.registered , true)"></ws-chip>
              </td>
              <td width="10px" class="text-no-wrap">
                <ws-chip icon="mdi-calendar" :color="wsACCENT" :text="PARSE_TIME_STAMP(item.last_access , true)"></ws-chip>
              </td>
              <td width="10px">
                <ws-chip icon="mdi-school" :text="item.courses_count" bold :color="wsACCENT" />
              </td>
              <td width="10px">
                <ws-chip icon="mdi-cart" :text="item.orders_count" bold :color="wsACCENT" />
              </td>
              <td width="10px" class="text-no-wrap">
                <ws-chip icon="mdi-cash" :text="item.total_income + ' ' + BUSINESS_CURRENCY" bold :color="wsACCENT" />
              </td>
              <td width="10px" class="text-no-wrap">
                <h5>{{ item.country }}</h5>
              </td>
              <td width="10px" class="text-no-wrap">
                <h5>{{ item.city }}</h5>
              </td>

              <template v-for="(field , i) in customFields" >
                <td :key="i + item.uuid">
                  <h5 class="text-center">{{ item['custom_field_' + field.id] }}</h5>
                </td>
              </template>


              <td width="10px">
                <ft-select @input="editAction($event, item)" :items="editActionTypes(selectedUser)" >
                  <v-btn :color="wsATTENTION" icon>
                    <v-icon>mdi-pencil-circle</v-icon>
                  </v-btn>
                </ft-select>
              </td>


            </tr>
          </template>
        </ws-data-table>
      </v-sheet>
    </template>

    <template #dialog>

      <!-- Display  Student dialog window -->
      <ws-dialog v-model="displayNewClientDialog"
                 :show-save="false"
                 :title="$t('AddClient')">

        <v-btn :style="`border: 1px solid ${wsBACKGROUND}`" x-large block @click="displayAddStudent" height="30"  text  class="noCaps justify-start py-6 mb-2" >
          <v-icon :color="wsATTENTION"  class="mr-3">mdi-account-plus</v-icon>
          {{ $t('AddNewClient') }}
        </v-btn>
        <v-btn :style="`border: 1px solid ${wsBACKGROUND}`" x-large block @click="displayImport" height="30"  text  class="noCaps justify-start py-6" >
          <v-icon  :color="wsATTENTION"  class="mr-3">mdi-table-arrow-left</v-icon>
          {{ $t('ImportFromFile') }}
        </v-btn>

      </ws-dialog>

      <!-- Display Add Student dialog window -->
      <ws-dialog
          v-model="displayAddClientDialog" width="600"
          :title="$t('AddNewClient')"
          @save="addNewClient">

        <div class="px-1 pb-1">
          <v-row>
            <v-col cols="6">
              <ws-text-field
                  v-model="entityData.firstname"
                  :placeholder="$t('Firstname')"
                  :label="$t('Firstname')"
                  :error="nameError"
                  @input="nameError = false"
              />
            </v-col>
            <v-col cols="6">
              <ws-text-field
                  v-model="entityData.lastname"
                  :placeholder="$t('Lastname')"
                  :label="$t('Lastname')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <ws-text-field
                  v-model="entityData.password"
                  :placeholder="$t('Password')"
                  :label="$t('Password')"
                  :error="passwordError"
                  @input="passwordError = false"
              />
            </v-col>
            <v-col cols="6">
              <ws-text-field
                  v-model="entityData.repeat_password"
                  :placeholder="$t('PasswordRepeat')"
                  :label="$t('PasswordRepeat')"
                  :error="passwordError"
                  @input="passwordError = false"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <ws-text-field
                  v-model="entityData.email"
                  :placeholder="$t('Email')"
                  :label="$t('Email')"
                  :error="emailError || userError"
                  @input="emailError = false ; userError = false"
              />
            </v-col>
            <v-col cols="6">
              <ws-phone-input
                  :label="$t('Phone')"
                  :error="phoneError || userError"
                  @input="phoneError=false ; userError = false "
                  v-model="entityData.phone"
                  hide-details
              />
            </v-col>
          </v-row>
          <v-row class="mt-1">
            <v-col cols="6">
              <wsDatePicker :label="$t('Birthday')" :placeholder="$t('ChooseDate')" v-model="entityData.birthday" />
            </v-col>
            <v-col cols="6">
              <ws-text-field
                  v-model="entityData.country"
                  :placeholder="$t('Country')"
                  :label="$t('Country')"
              />
            </v-col>
          </v-row>
        </div>


      </ws-dialog>
      <!-- Display Import Student from Clients dialog window -->
      <ws-dialog
          v-if="displayImportClientDialog"
          v-model="displayImportClientDialog"
          :width="importComplete ? '900' : '600' "
          :title="$t('ImportStudents')"
          :showSave="!displayImportClientDialog"
          :cancelText="importCancelText"
          @cancel="importCancelButton"
          cancel-action>

        <div v-if="!importComplete " >

          <v-btn block class="noCaps mb-4" outlined :color="wsACCENT" download :href="'https://' + $store.state.second_hostname + '/library/import_examples/import_table_example_clients.xlsx'">
            {{ $t('DownloadImportExample') }}
            <v-icon>mdi-download</v-icon>
          </v-btn>

          <h5 class="text-center font-weight-light"> {{$t('UploadNewUsersTableInFormats')}} :  </h5>
          <h5 class="text-center mt-3"> .xls, .xlsx </h5>
          <wsImageUpload class="mt-6" @upload="uploadUsers" />
        </div>
        <div v-if="importComplete && !reviewErrors">

          <h3 :style="`color: `" class="mb-5 mb-2 text-center"> {{ $t('ImportComplete')}}</h3>

          <v-simple-table dense>
            <tbody>
            <!-- File -->
            <tr>
              <td width="50px">
                <h5 class="font-weight-medium">{{ $t('File') }}</h5>
              </td>
              <td>
                <div class="d-flex align-center">
                  <v-icon color="grey darken-3" small class="mr-1">mdi-file</v-icon>
                  <h5 class="font-weight-medium" >{{ importResult.file}}</h5>
                </div>
              </td>
            </tr>
            <!-- New Users-->
            <tr>
              <td width="60px" class="py-3 text-no-wrap">
                <div class="d-flex align-center">
                  <v-icon :color="wsDARKER" small class="mr-1">mdi-account</v-icon>
                  <h5>{{$t('NewUsers')}}</h5>
                </div>
              </td>
              <td>
                <h5>{{ importResult.users_created_count}}</h5>
              </td>
            </tr>
            <!-- New Teacher Profiles-->
            <tr v-if="importResult.teacher_profiles_count > 0">
              <td width="50px" class="py-3 text-no-wrap">
                <div class="d-flex align-center">
                  <v-icon  small class="mr-1">mdi-account-tie</v-icon>
                  <h5>{{ $t('TeacherProfiles') }}</h5>
                </div>
              </td>
              <td>
                <h5>{{ importResult.teacher_profiles_count }}</h5>
              </td>
            </tr>
            <!-- Failed Rows -->
            <tr>
              <td width="50px">
                <h5 class="font-weight-medium">{{ $t('FailedRows') }}</h5>
              </td>
              <td>
                <h5 class="font-weight-medium" >{{  importResult.failed.length }}</h5>
              </td>
            </tr>
            <tr>
              <td width="50px">
                <h5 class="font-weight-medium">{{ $t('ImportErrors') }}</h5>
              </td>
              <td>
                <h5 class="font-weight-medium" >{{importResult.import_errors.length }}</h5>
              </td>
            </tr>
            </tbody>
          </v-simple-table>


          <v-btn @click="reviewErrors = true;"
                 class="mt-6 noCaps"
                 elevation="0"
                 block :color="wsDARK" dark>{{ $t('Review') }}
          </v-btn>

        </div>
        <div v-if="reviewErrors">

          <!-- New Users-->
          <div v-if="importResult.new_users.length > 0">
            <h4 class="text-center">{{ $t('NewUsers') }}: </h4>
            <v-simple-table class="mt-3 mb-3">
              <tbody>
              <tr v-for="(item,i) in importResult.new_users" :key="i + 'users'">
                <td width="1px" class="text-no-wrap px-1">
                  <h5 class="font-weight-light">{{ i + 1 }}</h5>
                </td>
                <td  width="1px" class="text-no-wrap px-2" >
                  <h5>{{ item.name }} </h5>
                </td>
                <td  width="1px" class="text-no-wrap px-2" >
                  <h6 class="font-weight-light">{{ item.email }} {{item.phone}}</h6>
                </td>
                <td class="pl-6">
                  <div class="d-flex">
                    <v-icon small :color="wsDARK" class="mr-2">mdi-safe</v-icon>
                    <h5 class="font-weight-light">{{ $t(item.password)}}</h5>
                  </div>
                </td>
              </tr>
              </tbody>
            </v-simple-table>
          </div>
          <!-- Import Fails-->
          <div v-if="importResult.failed.length > 0">
            <h4 class="text-center">{{ $t('ImportFailedRows') }}: </h4>
            <v-simple-table class="mt-3 mb-3">
              <tbody>
              <tr v-for="(row,i) in importResult.failed" :key="i">
                <td width="1px" class="text-no-wrap px-1">
                  <h5 class="font-weight-light">{{ row.index }}</h5>
                </td>
                <td>
                  <div class="d-flex">
                    <v-icon small :color="wsATTENTION" class="mr-2">mdi-alert-circle-outline</v-icon>
                    <h5 class="mr-5"> {{ $t('ErrorsInFields') }}:</h5>
                    <h5 v-for="field in row._error_fields" :key="field"
                        class="font-weight-light mr-3">
                      {{ field }}
                    </h5>
                  </div>
                </td>
              </tr>
              </tbody>
            </v-simple-table>
          </div>
          <!-- Import Errors-->
          <div v-if="importResult.import_errors.length > 0">
            <h4 class="text-center">{{ $t('DatabaseImportError') }}: </h4>
            <v-simple-table class="mt-3">
              <tbody>
              <tr v-for="(row,i) in importResult.import_errors" :key="i">
                <td width="1px" class="text-no-wrap px-1">
                  <h5 class="font-weight-light">{{ row.index }}</h5>
                </td>
                <td  width="1px" class="text-no-wrap px-2" >
                  <h5>{{row.row.sirname}} {{ row.row.name }} {{ row.row.middlename }} </h5>
                  <h6 class="font-weight-light">{{ row.row.email }} {{row.row.phone}}</h6>
                </td>
                <td class="pl-6">
                  <div class="d-flex">
                    <v-icon small :color="wsATTENTION" class="mr-2">mdi-alert-circle-outline</v-icon>
                    <h5 class="font-weight-light">{{ $t(row.error)}}</h5>
                  </div>
                </td>
              </tr>
              </tbody>
            </v-simple-table>
          </div>

        </div>


      </ws-dialog>
      <!-- Display New Student from Clients dialog window -->
      <wsDialog
          v-model="displayNewTagDialog"
          :title="$t('AddTag')"
          @save="addNewTag">
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
            <v-text-field
                v-model="entityData.name"
                :placeholder="$t('Name')"
                dense outlined />
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <ws-color-picker
                v-model="entityData.color"
                :placeholder="$t('Color')"
            ></ws-color-picker>
          </v-col>
        </v-row>
      </wsDialog>

      <!-- Display Delete Student dialog window -->
      <ws-dialog
          v-model="displayDeleteUser"
          @save="deleteUser"
          :save-text="$t('Delete')"
          :title="$t('DeleteUser')"
      >
        <h5 class="mb-5 text-center"> {{ $t('ConfirmClientDeleteMessage') }}</h5>
        <h5 class="mb-5 text-center"> {{ $t('ConfirmClientDeleteInstruction') }}</h5>

        <h4 class="mb-5 text-center"> {{ selectedUser.email }}</h4>
        <ws-text-field
            v-model="deleteUserData"
        />

      </ws-dialog>

      <ft-select
          @input="editAction($event, selectedUser)"
          :items="editActionTypes(selectedUser)"
          :expanded="displayContextMenu"
          :x="x"
          :y="y"
          absolute
      />



    </template>

  </dash-page-new>
</template>

<script>
import {mapActions} from "vuex";
import wsImageUpload from "@/components/UI/wsImageUpload";
import languagesList from "@/mixins/languagesList";
import spectatorMode from "@/mixins/spectatorMode";

export default {
  name: "BusinessClients",
  mixins : [languagesList,spectatorMode],
  components : {
    wsImageUpload
  },
  data(){
    return {

      displayDeleteUser : false,
      deleteUserData : null,

      customFields : [],
      referralsSelect : [],
      customFieldsFilters : [],

      displayNewClientDialog : false,
      displayAddClientDialog : false,
      displayImportClientDialog : false,

      passwordError : false,
      emailError : false,
      phoneError : false,
      nameError : false,
      userError : false,

      // import variables
      fromStudentsStep : 0,
      importResult : {},
      importComplete : false,
      reviewErrors : false,
      fieldsErrors : {},


      displayNewTagDialog : false,
      entityData : {},
      tagsSelect : [],
      filterTags : [],
      search : '',
      filter : {},
      items: [],
      displayContextMenu : false,
      x : 0,
      y : 0,
      selectedUser : {},

      reg: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/ ,

    }
  },
  computed : {
    headers() {
      let items = [
        { text : this.$t('HumanName')      , value : 'name'          },
        { text : this.$t('Phone')          , value : 'phone'         },
        { text : this.$t('DateRegistered') , value : 'registered'    },
        { text : this.$t('LastActivity')   , value : 'last_access'   },
        { text : this.$t('Courses')        , value : 'courses_count' },
        { text : this.$t('Orders')         , value : 'orders_count'  },
        { text : this.$t('Income')         , value : 'total_income'  },
        { text : this.$t('Country')        , value : 'country'       },
        { text : this.$t('City')           , value : 'city'          },
        { text : this.$t('Email')          , value : 'email' , align: 'd-none'},
      ]

      this.customFields.forEach(field => {
        items.push( { text : field.name , value : "custom_field_" + field.id })
      })

      items.push( { value : 'action', sortable : false } )

      return items
    },
    itemsFiltered() {
      let items = this.items

      if ( Array.isArray(this.filter.tags) ) {
        if ( this.filter.tags.length > 0 ) {
          items = items.filter( el=>
              el.tags.map(tag => tag.uuid).filter(tag_uuid => this.filter.tags.includes(tag_uuid)).length === this.filter.tags.length
          )
        }
      }

      if ( Array.isArray(this.filter.country) ) {
        if ( this.filter.country.length > 0 ) {
          items = items.filter( el=> this.filter.country.includes(el.country) )
        }
      }

      if ( this.filter.referral ) {
        if ( this.filter.referral === '$no_referral') {
          items = items.filter( el=> !el.referral )
        } else {
          items = items.filter( el=> el.referral ===  this.filter.referral )
        }


      }

      this.customFieldsFilters.forEach(field => {

        if ( field.type === 'select_multiple' && this.filter[field.value] ) {

          if ( Array.isArray( this.filter[ field.value])  ) {
            if (this.filter[field.value].length > 0 ) {
              items = items.filter( el=> this.filter[field.value].includes(el[field.value]) )
            }
          }
        }
      })

      return items
    },
    tagsSelectFiltered() {

      let items = this.tagsSelect

      items.forEach(tag => {
        if ( this.filterTags.includes(tag.value)) {
          tag.icon = 'mdi-minus'
        } else {
          tag.icon = 'mdi-plus'
        }
      })

      return items

    },
    filterItems() {
      let items = [
          { text : this.$t('Tags') , value : 'tags' , type : 'select_multiple' , items : this.tagsSelect },
          { text : this.$t('Country') , value : 'country' , type : 'select_multiple' , items : this.COUNTRIES_SELECT }
      ]
      if ( this.referralsSelect.length > 0 ) {
        items.push({ text : this.$t('Referrals') , value : 'referral' , type : 'select' , items : this.referralsSelect })
      }

      items = [...items , ...this.customFieldsFilters ]

      return items

    },
    importCancelText() {
      if (this.importComplete ) { return this.reviewErrors ? this.$t('Back') : this.$t('Finish') }
      if (this.reviewErrors) { return this.$t('Back') }
      return this.$t('Back')
    },
  },
  watch : {
    'EVENTS_TRIGGER.BUSINESS_USER'() {
      this.initPage()
    },
  },
  methods : {
    ...mapActions( 'crmSystem', [
        'GET_BUSINESS_CLIENTS' ,
        'ADD_NEW_TAG',
        'ADD_CLIENT_PROFILE',
        'DELETE_CLIENT_PROFILE',
        'EDIT_CLIENT_TAGS'
    ]),
    ...mapActions('import',['UPLOAD_IMPORT_USERS']),

    ...mapActions('export',[
      'EXPORT_TABLE_DATA'
    ]),
    getExportData($event) {
      this.itemsForExport = $event
    },

    async exportData() {
      let data = {
        data : this.itemsForExport,
        headers : this.headers
      }
      let blob = await this.EXPORT_TABLE_DATA(data)
      if ( !blob ) {
        return
      }
      var url  = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = 'import_data.xlsx';
      document.body.appendChild(a);
      a.click();
      a.remove();

      this.notify(this.$t('TableExported'))

    },

    openDeleteUser(item) {
      this.deleteUserData = null
      this.displayDeleteUser = true
      this.selectedUser = item
    },
    async deleteUser() {
      if ( this.selectedUser.email !== this.deleteUserData ) {
        return this.notify(this.$t('WrongUserEmail'),'warning')
      }
      let result = await this.DELETE_CLIENT_PROFILE(this.selectedUser.uuid)
      if ( !result ) {
        return this.notify(this.$t('Error'),'warning')
      }


      let index = this.items.findIndex( el=> el.uuid === this.selectedUser.uuid )
      if ( index === -1  ) {
        return this.notify(this.$t('Error'),'warning')
      }
      this.index = this.items.splice(index, 1)

      this.notify(this.$t('UserDeleted'))
      this.displayDeleteUser = false
    },

    displayNewClient() {
      this.fieldsErrors = {}
      this.entityData = {}
      this.displayNewClientDialog = true
    },
    displayAddStudent() {
      this.entityData = {}
      this.newEntity = true
      this.displayNewClientDialog = false
      this.displayAddClientDialog = true
    },
    async addNewClient() {

      if ( !this.entityData.email) {
        this.emailError = true
        return this.notify(this.$t('EnterEmail'))
      }
      if(  !this.reg.test(this.entityData.email)  ) {
        this.emailError = true
        this.notify(this.$t('WrongEmail'));
        return
      }
      if ( !this.entityData.password) {
        this.passwordError = true
        return this.notify(this.$t('EnterPassword'))
      }
      if ( this.entityData.password !== this.entityData.repeat_password ) {
        this.passwordError = true
        return this.notify(this.$t('PasswordNotMatch'))
      }
      if ( !this.entityData.phone) {
        this.phoneError = true
        return this.notify(this.$t('EnterPhone'))
      }
      if ( !this.entityData.firstname) {
        this.nameError = true
        return this.notify(this.$t('EnterFirstname'))
      }


      let result = await this.ADD_CLIENT_PROFILE(this.entityData)
      if ( !result ) {

        if ( this.$store.state.ajax.error === 'User already exist' ) {
          this.userError = true
          this.userError = true
          this.notify(this.$t('UserAlreadyExists'))
        } else {
          this.notify(this.$t('Error'))
        }

        return
      }
      this.items.unshift(result)
      this.notify(this.$t('ClientProfileCreated'))
      this.displayAddClientDialog = false
    },
    
    async uploadUsers(file) {
      this.importComplete     = false
      this.importResult = {
        import_errors : [],
        failed : [],
        new_users : []
      }
      let data = {
        fileFormData : file
      }
      let result = await this.UPLOAD_IMPORT_USERS(data)
      if ( !result ) { return false }
      this.notify(this.$t('ImportFinished'))
      this.initPage()
      this.importComplete = true
      this.importResult = result
    },
    displayImport() {
      this.newEntity = true
      this.displayDialog = false
      this.displayImportClientDialog = true
    },
    importCancelButton() {

      if (this.reviewErrors) {
        this.reviewErrors = false
        return
      }

      this.displayImportClientDialog = false
      this.displayNewClientDialog = true

      if (this.importComplete) {
        this.importComplete = false
        this.reviewErrors = false
        this.importResult = false
      }
    },
    
    editAction(type,item) {
      this.selectedUser = item
      switch(type) {
        case 'add_note'         : return this.displayAddEvent(item, 'note');
        case 'add_call'         : return this.displayAddEvent(item, 'call');
        case 'add_video_call'   : return this.displayAddEvent(item, 'video_call');
        case 'add_task'         : return this.displayAddEvent(item, 'task');
        case 'archive_business' : return this.displayArchiveBusiness();
        case 'ADD_NEW_TAG'      : return this.displayAddNewTag();
        case 'login_as_user'    : return this.loginAsUser(this.selectedUser.uuid , this.businessDashLink('subscribers') );
        case 'delete' : return this.openDeleteUser(item)
      }

      if (type.includes('add_tag_') ) {
        this.changeTag(type.replace('add_tag_',''),'add')
      }
      if (type.includes('remove_tag_')) {
        this.changeTag(type.replace('remove_tag_',''),'remove')
      }

    },
    editActionTypes(item) {
      return [
        { text: this.$t('ViewClientCard')  , icon : 'mdi-eye'   ,  route : this.businessDashLink('subscribers/' + item.uuid) + '/timeline' },
        { text: this.$t('LogInAsUser')     , value : 'login_as_user' ,  icon : 'mdi-login'   },

        { text: this.$t('Tags'),  icon : 'mdi-tag', children : this.getBusinessFreeTagsSelect(item.tags) },
        { text: this.$t('Delete') , icon : 'mdi-delete' , value : 'delete'}
        // { text: this.$t('Add') ,  icon : 'mdi-plus' , children : [
        //     { text: this.$t('Note')      , icon : 'mdi-plus'  ,  value : 'add_note' },
        //     { text: this.$t('Call')      , icon : 'mdi-plus'  ,  value : 'add_call' },
        //     { text: this.$t('VideoCall') , icon : 'mdi-plus'  ,  value : 'add_video_call' },
        //     { text: this.$t('Task')      , icon : 'mdi-plus'  ,  value : 'add_task' },
        //     { text: this.$t('Bug')      , icon : 'mdi-plus'  ,  value : 'add_bug' },
        //   ] },
        // { text: 'Приховати' , icon : 'mdi-close' ,  value : 'archive_business' }
      ]
    },

    async addNewTag() {

      if (!this.entityData.name) {
        return this.notify(this.$t('PleaseEnterName'))
      }

      let result = await this.ADD_NEW_TAG(this.entityData)
      if ( !result || result === true ) {
        return
      }
      this.tagsSelect.push(result)
      this.changeTag(result.value,'add')
      this.displayNewTagDialog = false
    },
    async changeTag(tag,type) {
      let tags = this.selectedUser.tags
      let tagsArray = tags.map(el => el.uuid)
      if ( type === 'add' ) {
        tagsArray.push(tag)
      }
      if ( type === 'remove' ) {
        let index = tagsArray.findIndex( el => el === tag )
        if ( index === -1 ) { return }
        tagsArray.splice(index,1)
      }

      let data = {
        user_id : this.selectedUser.uuid,
        tags : tagsArray
      }

      let result = await this.EDIT_CLIENT_TAGS(data);
      if ( !result ) { return }
      this.selectedUser.tags = result !== true ?  result : []

    },

    getTagName(tag) {
      let tagEntity = this.tagsSelect.find(el => el.value === tag)
      if ( tagEntity ) { return tagEntity.text }
      return 'tag'
    },
    getBusinessFreeTagsSelect(tags = []) {

      let items = JSON.parse(JSON.stringify(this.tagsSelect) )

      if (!tags ) { return [] }

      let  tagsArray = tags.length > 0 ?  tags.map( el => el.uuid ) : []

      items.forEach((item) => {
        if ( tagsArray.includes(item.value) ) {
          item.value = 'remove_tag_' + item.value
          item.icon = 'mdi-minus'
        } else {
          item.value = 'add_tag_'    + item.value
          item.icon = 'mdi-plus'
        }
      })

      items.push({
        text : this.$t('AddTag') ,
        value : 'ADD_NEW_TAG'
      })


      return items
    },
    changeTagFilter(tag) {
      if ( this.filterTags.includes(tag)) {
        let index = this.filterTags.findIndex(el => el === tag)
        if ( index === -1 ) { return }
        this.filterTags.splice(index,1)
      } else {
        this.filterTags.push(tag)
      }
    },

    displayAddNewTag() {
      this.entityData = {}
      this.displayNewTagDialog = true
    },
    openMenu($event , item) {
      this.selectedUser = item
      $event.preventDefault()
      this.displayContextMenu = false
      this.x = $event.clientX
      this.y = $event.clientY
      this.$nextTick(() => {
        this.displayContextMenu = true
      })
    },

    async initPage() {
      let result = await this.GET_BUSINESS_CLIENTS()
      if ( !result || result === true ) { return }
      this.items = result.clients
      this.tagsSelect = result.tags
      this.customFields = result.custom_fields
      this.customFieldsFilters = result.custom_fields_filters
      this.referralsSelect = result.referrals || []
      if ( this.referralsSelect.length > 0 )  {
        this.referralsSelect.unshift({ text : this.$t('referral.none') , value : '$no_referral' })
      }
      this.READ_EVENTS('business_user')
    }
  },
  mounted() {
   this.initPage()
  }

}
</script>

<style scoped>

</style>